/* App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.body-blurred {
  transition: filter 0.3s ease;
}

.navbar-focused .body-blurred {
  filter: blur(0); /* No blur on the navbar */
}

.nav-item:hover .body-blurred {
  filter: blur(5px);
}
